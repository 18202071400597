.site-footer-decor {
	display: none;
}

@media (width >= 768px) {
	.site-footer-decor {
		width: 50px;
		height: 50px;
		position: absolute;
		display: block;
		bottom: -50px;
	}

	.site-footer-decor--left {
		background-image: radial-gradient(ellipse at top right, rgba(255,255,255,1) 40%,rgba(255,255,255,0) 60%);
		left: -50px;
	}

	.site-footer-decor--right {
		background-image: radial-gradient(ellipse at top left, rgba(255,255,255,1) 40%,rgba(255,255,255,0) 60%);	
		right: -50px;
	}
}