.header-submenu {
	display: none;
}

.submenu-is-open + .header-submenu {
	display: flex;
}

.header-submenu {
	padding: 10px 15px;
	border-radius: 8px;
	z-index: 5;
	background-color: #FFF;
}

@media (width >= 992px) {
	.header-submenu {
		display: flex;
		position: absolute;
		top: 30px;
		width: auto;
		visibility: hidden;
		opacity: 0;
		transform-origin: top left;
		border: 1px solid #F93E51;
		transition: visibility .3s, opacity .3s;
	}

	.submenu-is-open + .header-submenu {
		visibility: visible;
		opacity: 1;
	}

}

.header-submenu li {
	display: flex;
}


@media (width <= 991px) {
	.header-submenu {
		flex-direction: column;
		justify-content: center;
	}

	.menu-is-open .header-submenu {
		padding: 0;
		background-color: rgba(255, 255, 255, 0.9);
	}

	.header-submenu>li:not(:last-child) {
		border-bottom: 1px solid #ff5b96;
	}

	.header-submenu>li:first-child {
		border-top: 1px solid #ff5b96;
	} 

	.header-submenu a {
		padding-left: 0;
		width: 100%;
		background-color: transparent;
		padding-left: 15px;
	}
}

.header-submenu a {
	display: flex;
	align-items: center;
	color: #9a0089;
	transition: color .3s;
	font-size: 18px;
	white-space: nowrap;
	text-decoration: none;
	font-family: Verdana, Geneva, sans-serif;
	font-size: 14px;
	line-height: normal;
	font-weight: normal;
	padding: 5px 10px;
	background-color: white;
}

.header-submenu a:hover {
	color: #ff5b96;
}


.menu-is-open .header-submenu {
	position: static;
	padding: 0 15px;
	width: 100%;
}

.menu-is-open .header-submenu li {
	min-height: 40px;
	height: auto;
}