.about-content {
	padding: 50px 40px;
	width: 100%;
}

.about-video {
	width: 100%;
}

.about-video-wrapper {
	margin: 0 auto 20px;
	width: 100%;
	max-width: 800px;
}

@media (width <= 991px) {
	.about-content {
		padding-top: 10px;
	}
}

@media (width <= 641px) {
	.about-content {
		padding: 10px 0 0;
	}
}

.youtube {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	display: inline-block;
	overflow: hidden;
	transition: all 200ms ease-out;
	cursor: pointer;
	width: 100%;
}

.youtube::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	z-index: 1;		
}

.youtube .play {
	top: calc(50% - 31px);
	left: calc(50% - 31px);
	background-image: url(../img/video.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 61px 61px;
	position: absolute;
	height: 61px;
	width: 61px;
	will-change: opacity, transform;
	transition: transform .3s;
	opacity: .8;
	transform: scale(1);
	z-index: 1;
}

.youtube .play:hover {
	opacity: 1;
	transform: scale(1.5);
}
