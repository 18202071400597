.site-footer {
	padding-left: 15px;
	padding-right: 15px;
	position: relative;
}

.site-footer::before {
	position: absolute;
	display: block;
	width: 100%;
	height: 50px;
	bottom: 0;
	left: 0;
	content: "";
	background-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 95%);
	bottom: -50px;
}

.site-footer-wrapper {
	display: flex;
	justify-content: space-between;
}

.site-footer-left,
.site-footer-right {
	flex-basis: auto;
}

@media (width <= 479px) {
	.site-footer-wrapper {
		flex-direction: column;
		align-items: center;
	}

	.site-footer-left {
		margin-bottom: 30px;
	}
}