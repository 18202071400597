.index-content {
	width: calc(100% + 30px);
	height: auto;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	margin: -75px -15px 20px;
	position: relative;
	z-index: 1;
	pointer-events: none;
}

@media (width <= 991px) {
	.index-content {
		margin-top: -108px;
	}
}

@media (width <= 479px) {
	.index-content {
		margin-top: -88px;
	}
}

.index-content img {
	display: block;
	width: 100%;
	height: auto;
	max-width: 800px;
	min-width: 400px;
	margin-left: auto;
	margin-right: auto;
	object-position: center;
}