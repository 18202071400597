.contact-content {
	padding: 50px 40px;
}

@media (width <= 991px) {
	.contact-content {
		padding: 0 20px 50px;
	}
}

@media (width <= 479px) {
	.contact-content {
		padding: 0 10px 50px;
	}
}

.contact-content-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
}

.contact-content-info,
.contact-content-form {
	flex-basis: auto;
}

.contact-content-info {
	padding-top: 15px;
}

.content-info-block {
	margin-bottom: 20px;
}

.contact-content-info p,
.contact-content-info a,
.content-info-block__title,
.contact-content-form h3 {
	font-family: Verdana, Geneva, sans-serif;
	line-height: normal;
	color: #9A0089;
	margin-top: 0;
	margin-bottom: 5px;
}

.contact-content-info a {
	text-decoration: none;
	border-bottom: 1px solid transparent;
	transition: border-bottom .3s;
}

.contact-content-info a:hover {
	border-bottom: 1px solid currentColor;
}

.contact-content-info p,
.content-info-block__title {
	font-size: 16px;
}

.content-info-block__title {
	display: block;
	font-weight: bold;
	margin-bottom: 10px;
}

.contact-content-form {
	padding: 15px 25px 25px;
	background-color: #F8F8FF;
	max-width: 100%;
}

.contact-content-form form {
	display: flex;
	flex-direction: column;
}

.contact-content-form h3 {
	font-size: 20px;
	margin-bottom: 20px;
	text-align: center;
}

.contact-content-form label {
	display: block;
	padding-bottom: 5px;
	color: #777;
	font-family: Verdana, Geneva, sans-serif;
	font-size: 14px;
}

.contact-content-form input,
.contact-content-form textarea {
	margin-bottom: 15px;
	width: 260px;
	border: 1px solid #ccc;
	background-color: #FFF;
	font-family: Verdana, Geneva, sans-serif;
	font-size: 14px;
	color: #999;
	padding: 5px 10px;
	outline: transparent;
	border-radius: 4px;
	max-width: 100%;
}

.contact-content-form input {
	height: 25px;
}

.contact-content-form textarea {
	height: 85px;
	min-height: 85px;
	resize: vertical;
}

.contact-content-form button {
	margin: 5px auto;
	width: 150px;
	height: 40px;
	border-radius: 5px;
	border: 1px solid #ffa826;
	cursor: pointer;
	font-family: Verdana, Geneva, sans-serif;
	font-size: 14px;
	letter-spacing: 1px;
	color: white;
	background-image: linear-gradient(to bottom, #ff820b, #ffa826);
	background-color: #FFF;
}

.contact-content-form button:hover {
	background-image: linear-gradient(to bottom, #ffa826, #ff820b);
}

@media (width <= 641px) {
	.contact-content-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	}

	.contact-content-info {
		display: flex;
		justify-content: space-between;
		width: 100%;
		max-width: 400px;
		flex-wrap: wrap;
		margin: 0 auto 20px;
	}

	.content-info-block {
		flex-basis: 180px;
	}

	.contact-content-form {
		margin-bottom: 30px;
	}
}

@media (width <= 479px) {
	.contact-content-info {
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	.content-info-block {
		flex-basis: auto;
	}
}