.shops-content {
	padding: 50px 40px;
}

@media (width <= 991px) {
	.shops-content {
		padding: 0 20px 50px;
	}
}

.shops-content .page-content p {
	margin-bottom: 10px;
}

@media (width <= 479px) {
	.shops-content {
		padding: 0 10px 50px;
	}

	.shops-content .page-content p {
		margin-bottom: 15px;
	}
}