.site-header-decor {
	display: none;
}

@media (width >= 768px) {
	.site-header-decor {
		width: 50px;
		height: 50px;
		position: absolute;
		display: block;
		top: -50px;
	}

	.site-header-decor--left {
		background-image: radial-gradient(ellipse at bottom right, rgba(255,255,255,1) 40%,rgba(255,255,255,0) 60%);
		left: -50px;
	}

	.site-header-decor--right {
		background-image: radial-gradient(ellipse at bottom left, rgba(255,255,255,1) 40%,rgba(255,255,255,0) 60%);	
		right: -50px;
	}
}