.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 50px;
}

.page-item a {
	transition: background-color .3s, color .3s;
	text-decoration: none;
	margin-left: 5px;
	margin-right: 5px;
}

.page-item:first-child a,
.page-item:last-child a {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 45px;
}

.page-item:first-child a svg path,
.page-item:last-child a svg path {
	fill: #9a0089;
}

.page-item:first-child a:hover svg path,
.page-item:last-child a:hover svg path {
	fill: #ff5b96;
}

.page-item:first-child svg,
.page-item:last-child svg {
	width: 8px;
	height: auto;
}

.page-item:first-child svg {
	transform: rotate(180deg);
}

.page-item:first-child {
	margin-right: 10px;
}

.page-item:last-child {
	margin-left: 10px;
}

.page-item a {
	font-size: 30px;
	color: #9a0089;
	padding-left: 5px;
	padding-right: 5px;
}

.page-item.active a,
.page-item a:hover {
	color: #ff5b96;
}