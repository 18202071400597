@media (width <= 767px) {
	.site-main {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.site-main {
	margin-bottom: auto;
	width: 100%;
}