@media (width >= 480px) {}
@media (width >= 768px) {}
@media (width >= 868px) {}
@media (width >= 992px) {}
@media (width >= 1280px) {}
@media (width <= 1279px) {}
@media (width <= 991px) {}
@media (width <= 867px) {}
@media (width <= 767px) {}
@media (width <= 641px) {}
@media (width <= 479px) {}