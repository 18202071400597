.general-wrapper {
	width: 100%;
	background-color: #FFF;
	min-height: calc(100vh - 140px);
	margin-top: 90px;
	margin-bottom: 50px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

@media (width >= 768px) {
	.general-wrapper {
		position: relative;
	}

	.general-wrapper::before,
	.general-wrapper::after {
		position: absolute;
		display: block;
		width: 50px;
		height: 100%;
		top: 0;
		content: "";
	}

	.general-wrapper::before {
		background-image: linear-gradient(270deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
		left: -50px;
	}

	.general-wrapper::after {
		background-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
		right: -50px;
	}
}