.collection-content {
	padding: 50px 20px;
	margin-right: auto;
	margin-left: auto;
}

.collection-slider {
	background-color: #F5F6FA;
	padding: 20px;
	margin-bottom: 30px;
}

.lSSlideOuter .lslide img {
	max-width: 100%;
	height: auto;
}

@media (width <= 991px) {
	.collection-content {
		padding: 10px 0;
	}
}

.lSSlideOuter .lSPager.lSGallery img {
	margin-left: auto;
	margin-right: auto;
}

.lSSlideOuter .lSPager.lSGallery li:last-child {
	display: none;
}

@media (width <= 479px) {
	.collection-slider {
		background-color: #F5F6FA;
		padding: 10px;
	}

	.lSSlideOuter .lslide img {
		/* max-width: 290px; */
		/* height: auto; */
	}
}

#lg-download {
	display: none;
}

.lSAction>a {
	opacity: 1;
}