.site-header-logo {
	display: flex;
	justify-content: center;
	position: relative;
	margin-top: -90px;
	z-index: 1;
}

@media (width <= 867px) {
	.site-header-logo {
		overflow: hidden;
	}
}

@media (width <= 479px) {
	.site-header-logo img {
		height: 100px;
		width: auto;
	}	
}