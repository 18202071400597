.site-header {
	position: relative;
}

.site-header::before {
	position: absolute;
	display: block;
	width: 100%;
	height: 50px;
	top: 0;
	content: "";
	background-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 95%);
	top: -50px;
}