.site-footer__logo-des {
	margin-bottom: 20px;
}

@media (width <= 479px) {
	.site-footer__logo-des {
		margin-bottom: 10px;
		text-align: center;
	}
}

.site-footer__logo-des a {
	font-family: Verdana, Geneva, sans-serif;
	font-size: 14px;
	line-height: normal;
	color: #9a0089;
	text-decoration: none;
}

.site-footer__logo-des a:hover {
	text-decoration: underline;
}