.page-content p,
.page-content ul,
.page-content ol,
.page-content h2,
.page-content h3 {
	font-family: Verdana, Geneva, sans-serif;
	color: #9A0089;
	margin-top: 0;
	margin-bottom: 15px;
}

.page-content p,
.page-content ul,
.page-content ol {
	font-size: 16px;
	line-height: 1.4;
}

.page-content ul {
	list-style: disc;
	list-style-position: inside;
}

.page-content ol {
	padding-left: 0;
	list-style-position: inside;
}

.page-content img {
	display: block;
	width: 100%;
	max-width: 600px;
	height: auto;
	margin: 0 auto 15px;
}

.page-content h2 {
	font-size: 20px;
	line-height: normal;
}

.page-content h3 {
	font-size: 18px;
	line-height: normal;
}