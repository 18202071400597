.site-footer-social {
	display: flex;
	margin-bottom: 20px;
}

@media (width <= 479px) {
	.site-footer-social {
		margin-bottom: 10px;
		justify-content: center;
	}
}

.site-footer-social a {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 35px;
	border: 1px solid #9a0089;
	border-radius: 50%;
	background-color: white;
	transition: background-color .3s, border-color .3s;
	text-decoration: none;
}

.site-footer-social a + a {
	margin-left: 18px;
}


.site-footer-social a:hover {
	background-color: #9a0089;
}

.site-footer-social a svg path {
	fill: #9a0089;
	transition: fill .3s;
}

.site-footer-social a:hover svg path {
	fill: white;
}

.site-footer__copy {
	font-family: Verdana, Geneva, sans-serif;
	font-size: 14px;
	line-height: normal;
	color: #9a0089;
}