@media (width >= 992px) {
	.header-button {
		display: none;
	}
}

@media (width <= 767px) {
	.header-button {
		padding-right: 20px;
	}
}

.header-button {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	height: 55px;
	cursor: pointer;
	margin-top: 0;
	margin-bottom: 20px;
}

@media (width <= 479px) {
	.header-button {
		margin-top: -10px;
		padding-right: 10px;
	}
}

.header-button__title  {
	font-size: 22px;
	color: #FF5B96;
	text-transform: uppercase;
	margin-right: 5px;
	pointer-events: none;
}

.header-button__btn {
	width: 38px;
	height: 42px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	border: none;
	padding: 10px;
	position: relative;
	outline: none;
	cursor: pointer;
}

.header-button__btn span {
	display: block;
	background-color: #ff5b96;
	width: 28px;
	height: 2px;
	will-change: transform;
	transition: transform .3s, background-color .3s;
}

.header-button__btn span {
	transform-origin: center center;
	transform: translate(0,0) rotate(0);
}

.header-button__btn span + span {margin-top: 7px;}

.menu-is-open .header-button__btn span:nth-child(1) {
	transform: translateY(9px) rotate(45deg);
}

.menu-is-open .header-button__btn span:nth-child(2) {
	transform: rotateY(90deg);
}

.menu-is-open .header-button__btn span:nth-child(3) {
	transform: translateY(-9px) rotate(-45deg);
}