.header-menu {
	width: 100%;
	display: none;
	justify-content: center;
	align-items: center;
	z-index: 3;
	margin-top: 20px;
}

@media (width >= 992px) {
	.header-menu {
		display: flex;
	}
}

.header-menu > li {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.header-menu > li > a {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	color: #9a0089;
	padding-left: 10px;
	padding-right: 10px;
	text-decoration: none;
	font-family: Verdana, Geneva, sans-serif;
	font-size: 18px;
	line-height: normal;
	font-weight: bold;
	transition: color .3s;
}

.header-menu > li > a:hover {
	color: #ff5b96;
}

.menu-is-open .header-menu {
	display: block;
	height: auto;
	position: absolute;
	top: 120px;
}

@media (width <= 991px) {
	.header-menu {
		display: flex;
		flex-direction: column;
		height: 0;
		display: none;
	}
}

@media (width <= 767px) {
	.menu-is-open .header-menu {
		top: 90px;
	}
}

@media (width <= 479px) {
	.menu-is-open .header-menu {
		top: 35px;
	}
}

.menu-is-open .header-menu>li {
	width: 100%;
	flex-direction: column;
	border-bottom: 1px solid #ff5b96;
}

.menu-is-open .header-menu>li:first-child {
	border-top: 1px solid #ff5b96;
} 

.menu-is-open .header-menu>li>a {
	background-color: rgba(255, 255, 255, 0.9);
	font-size: 17px;
	min-height: 40px;
	justify-content: flex-start;
	padding-left: 15px;
	padding-right: 15px;
}

.menu-is-open .has-submenu {
	position: relative;
}

.menu-is-open .has-submenu::after {
	position: absolute;
	display: block;
	width: 20px;
	height: 20px;
	top: 10px;
	right: 15px;
	content: "";
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS4wNSIgaGVpZ2h0PSI3LjcxIj4gIDxwYXRoIGZpbGw9IiNmZjViOTYiIGQ9Ik02Ljc2IDcuNDJMLjMxIDEuNjFBLjg3Ljg3IDAgMCAxIC4yMS4zOGwuMS0uMWExLjE0IDEuMTQgMCAwIDEgMS40NSAwbDUuNzUgNS4xNEwxMy4yMy4yOGExLjEzIDEuMTMgMCAwIDEgMS41MyAwIC44Ny44NyAwIDAgMSAuMDcgMS4yM2wtLjA3LjA3LTYuNSA1Ljg0YTEuMTQgMS4xNCAwIDAgMS0xLjUgMHoiLz48L3N2Zz4=);
	transition: transform .3s;
}

.menu-is-open .has-submenu.submenu-is-open::after {
	transform: rotateX(180deg);
}