.news-content-wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 50px 40px 0;
}

.news-card {
	flex-basis: 320px;
	max-width: 320px;
	display: flex;
	flex-direction: column;
	margin-bottom: 50px;
}

@media (width <= 991px) {
	.news-content-wrapper {
		padding: 0 20px 40px;
	}

	.news-card {
		flex-basis: calc(100% / 2 - 20px);
		max-width: calc(100% / 2 - 20px);
	}
}

@media (width <= 641px) {
	.news-content-wrapper {
		justify-content: center;
	}

	.news-card {
		flex-basis: 100%;
		max-width: 320px;
		margin-left: 10px;
		margin-right: 10px;
	}
}

@media (width <= 479px) {
	.news-content-wrapper {
		padding: 0;
	}
}

.news-date {
	margin-top: 0;
	margin-bottom: 5px;
	color: #999;
	font-size: 14px;
	line-height: normal;
	font-family: sans-serif;
}

.news-title {
	margin-top: 0;
	margin-bottom: 5px;
	color: #9a0089;
	font-size: 18px;
	line-height: normal;
	font-family: sans-serif;
	font-weight: bold;
	text-decoration: none;
	transition: color .3s;
}

.news-title:hover {
	color: #FF5B96;
}

.news-text {
	font-size: 14px;
	line-height: normal;
	font-family: sans-serif;
	color: #9a0089;
}