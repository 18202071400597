.article-content {
	padding: 50px 40px;
}

@media (width <= 991px) {
	.article-content {
		padding: 0 20px 50px;
	}
}

@media (width <= 479px) {
	.article-content {
		padding: 0 10px 50px;
	}
}

.article-content-title {
	display: block;
	margin-top: 0;
	margin-bottom: 20px;
	font-family: Verdana, Geneva, sans-serif;
	font-size: 18px;
	font-weight: bold;
	line-height: normal;
	text-align: center;
	color: #9A0089;
}