html {
	box-sizing: border-box;
}
*, *::before, *::after {
	box-sizing: inherit;
}

body {
	min-width: 320px;
	margin: 0;
	font-family: 'Fira Sans', Arial, sans-serif;
	font-weight: normal;
}

@media (width >= 768px) {
	body {
		background-image: url(../img/body-bg.jpg);
	}
}

a {
	outline-color: transparent!important;
}

a:hover,
a:focus {
	text-decoration: none;
}

ul {
	margin-top: 0;
	margin-bottom: 0;
	list-style: none;
	padding-left: 0;
}

@media (width >= 868px) {body {--container: 750px;}}
@media (width >= 992px) {body {--container: 870px;}}
@media (width >= 1300px) {body {--container: 1170px;}}

.container {
	width: 100%;
	max-width: var(--container);
	margin-left: auto;
	margin-right: auto;
}


@media (width <= 991px) {
	.container {
		padding-left: 50px;
		padding-right: 50px;
	}
}

@media (width <= 767px) {
	.container {
		padding-left: 0;
		padding-right: 0;
	}
}